<template>
  <v-card class="rounded-0">
    <v-card-title>
      <h4 class="white--text">{{ breach.name }} Statistics</h4>
    </v-card-title>
    <v-card-text class="body-2 white--text font-weight-light mt-6">
      <v-row>
        <v-col cols="5">
          <table width="340">
            <tbody>
              <tr v-for="(item, index) in topLeftTable" :key="index">
                <td class="info--text">
                  {{ item[0] }}
                </td>
                <td class="">{{ item[1] }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
        <v-col cols="5">
          <table width="340">
            <tbody>
              <tr v-for="(item, index) in topRightTable" :key="index">
                <td class="info--text">
                  {{ item[0] }}
                </td>
                <td class="">{{ item[1] }}</td>
              </tr>
            </tbody>
          </table>
        </v-col></v-row
      >
      <v-row>
        <v-col cols="12">
          <table width="95%">
            <tr>
              <th></th>
              <th
                class="info--text"
                style="font-weight: 300; font-size: small"
                v-for="(header, index) in mainHeaders"
                :key="index"
              >
                {{ header.formatted }}
              </th>
            </tr>
            <tr v-for="(item, index) in mainTable" :key="index">
              <td class="info--text">{{ item.name }}</td>
              <td
                v-for="(header, index) in mainHeaders"
                style="text-align: center; padding: 4px"
                :key="index"
              >
                {{ item[header.key] }}
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "BreachStats",
  data: () => ({
    topLeftKeys: [
      "partitions_checked",
      "missing_key",
      "total_records_processed",
      "total_records_normalized",
    ],
    topRightKeys: [
      "ends_in_comma",
      "ends_in_slashes",
      "begin_or_end_braces_brackets",
      "begin_or_end_quotes",
      "contains_json_key",
      "contains_html",
      "contains_byte_codes",
      "contains_special_chars",
    ],
  }),
  props: {
    stats: {
      type: Object,
      default: () => {},
    },
    breach: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    formatText(text) {
      text = text.replace(/_/g, " ");
      text = text.charAt(0).toUpperCase() + text.slice(1);
      return text;
    },
    getTableValues(tableKeys) {
      let tableStats = [];
      for (const key of tableKeys) {
        let title = this.formatText(key);
        tableStats.push([title, this.stats[key] || 0]);
      }
      return tableStats;
    },
  },
  computed: {
    topLeftTable() {
      return this.getTableValues(this.topLeftKeys);
    },
    topRightTable() {
      return this.getTableValues(this.topRightKeys);
    },
    mainHeaders() {
      let keyNames = Object.keys(this.stats)
        .filter((k) => !this.topLeftKeys.includes(k))
        .filter((k) => !this.topRightKeys.includes(k));
      const headerKeys = [];
      for (const key of keyNames) {
        headerKeys.push({ key: key, formatted: this.formatText(key) });
      }
      return headerKeys;
    },
    mainTable() {
      const tableData = [];
      const rowKeys = new Set();
      for (const key of this.mainHeaders) {
        for (const rowKey of Object.keys(this.stats[key.key])) {
          rowKeys.add(rowKey);
        }
      }

      for (const rowKey of rowKeys) {
        const thisRow = {};
        for (const headerKey of this.mainHeaders) {
          // [ {name: email, moved_from: 0, moved_to: 24}]
          thisRow.key = rowKey;
          thisRow.name = this.formatText(rowKey);
          thisRow[headerKey.key] = this.stats[headerKey.key][rowKey] || "";
        }
        tableData.push(thisRow);
      }
      console.log(tableData);
      return tableData;
    },
  },
};
</script>
