<script>
// https://vuetifyjs.com/en/getting-started/frequently-asked-questions/#questions

import { VTextField } from "vuetify/lib";
import { convertToUnit } from "vuetify/lib/util/helpers";

function vInputgenInputSlot() {
  return this.$createElement(
    "div",
    this.setBackgroundColor(this.backgroundColor, {
      staticClass: "v-input__slot-no-underline",
      style: { height: convertToUnit(this.height) },
      on: {
        click: this.onClick,
        mousedown: this.onMouseDown,
        mouseup: this.onMouseUp,
      },
      ref: "input-slot",
    }),
    [this.genDefaultSlot()]
  );
}

export default {
  extends: VTextField,
  name: "text-field-no-underline",
  props: {
    inputWidth: {
      type: String,
      default: "190px",
    },
  },
  methods: {
    genInputSlot() {
      /* const input = VInput.options.methods.genInputSlot.call(this); */
      const input = vInputgenInputSlot.call(this);
      input.children[0].children[0].data.style = { width: this.inputWidth };
      const prepend = this.genPrependInnerSlot();

      if (prepend) {
        input.children = input.children || [];
        input.children.unshift(prepend);
      }

      return input;
    },
  },
};
</script>

<style>
.v-input__slot-no-underline {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  min-height: inherit;
  position: relative;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}

.v-input__slot-no-underline input {
  /* min-width: 190px; */
}
</style>
