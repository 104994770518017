import store from "@/store/index";
import { formatDate } from "@/utils/common";
import BreachManagementDialog from "../BreachManagement/BreachManagementDialog";
import BreachStats from "./BreachStats.vue";
import editBreachFields from "../BreachPermalink/editBreachFields";
import {
  breachesBreachIdPut,
  breachesBreachIdRawfileDelete,
  breachesBreachIdRawfilePost,
  breachesBreachIdStatusPost,
} from "@/clients/tracelight";
import axios from "axios";

export default {
  name: "BreachPermalink",
  components: { BreachManagementDialog, BreachStats },
  data: () => ({
    environment: process.env.VUE_APP_ENV,
    name: null,
    hoveredItem: false,
    breachFields: editBreachFields,
    selected: null,
    statusInput: null,
    statusFilters: [
      "NEW",
      "INVALID",
      "ON HOLD",
      "LOADING COMPLETE",
      "LOADING IN PROGRESS",
      "LOADING NEEDS REVIEW",
      "NORMALIZATION COMPLETE",
      "NORMALIZATION IN PROGRESS",
      "NORMALIZATION NEEDS REVIEW",
      "PARTITIONING COMPLETE",
      "PARTITIONING IN PROGRESS",
      "PARTITIONING NEEDS REVIEW",
    ],
    stats: {},
    invalids: "",
    infoDialog: false,
    deleteDialog: false,
    fileName: "",
    files: [],
    renderComponent: true,
    dialogFields: {},
    dialog: false,
    snackbar: false,
    snackbarContent: "",
    primaryInfoKeys: [
      ["Breach Slug", "breach_slug"],
      ["Source", "source"],
      ["Archive Password", "archive_password"],
      ["Acquired", "acquisition_date"],
      ["Released", "release_date"],
    ],
    secondaryInfoKeys: [
      ["Issue Number", "issue_number"],
      ["Identified Breach", "is_identified"],
      ["Partial Breach", "is_partial"],
      ["Confirmed", "is_confirmed"],
      ["Total Lines", "total_lines"],
    ],
    descriptionKeys: [["Description", "description"]],
    notesKeys: [["Notes", "notes"]],
  }),
  created() {
    store.commit("breachManagement/replaceValue", {
      key: "breachId",
      value: this.$route.params.breachId,
    });
    store.dispatch("breachManagement/getSingleBreach");

    store.dispatch("breachManagement/listFilenames");
  },
  methods: {
    copyText() {
      const el = document.createElement("textarea");
      el.value = this.breach.commands.aws_sync_raw;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.snackbarContent = "AWS CLI command copied.";
      this.snackbar = true;
    },
    hoverItem(item) {
      this.hoveredItem = item;
    },
    unHoverItem() {
      this.hoveredItem = false;
    },
    openUploadFile() {
      document.getElementById("fileUploadInput").click();
    },
    updateStatus(breach_id) {
      const params = { breach_id: breach_id };
      const payload = { status: this.selected, note: this.statusInput };
      breachesBreachIdStatusPost(payload, params)
        .then((result) => {
          console.log(result.data);

          this.snackbarContent = "Breach status updated successfully!";
          this.snackbar = true;

          store.dispatch("breachManagement/getSingleBreach");
          this.statusInput = null;
          this.selected = null;
        })
        .catch((error) => {
          console.warn(error.response);
          this.snackbarContent = `Breach error — ${error.response.data.message}`;
          this.snackbar = true;
          return;
        });
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    deleteDialogOpen(name) {
      console.debug(name);
      this.name = name;
      this.deleteDialog = true;
    },
    deleteFile: function () {
      const name = this.name;
      const currentFilenames = this.filenames.filter((s) => s.name !== name);

      breachesBreachIdRawfileDelete(
        {},
        {
          breach_id: this.$route.params.breachId,
          filenames: name.replace(/^.*[\\\/]/, ""),
        },
        {}
      )
        .then((response) => {
          this.snackbarContent = `File deleted successfully`;
          this.snackbar = true;
          store.commit("breachManagement/replaceValue", {
            key: "filenames",
            value: currentFilenames,
          });
          store.dispatch("breachManagement/listFilenames");
        })
        .catch((error) => {
          console.warn(error.response);
          this.snackbarContent = `Breach error — ${error.response.data.message}`;
          this.snackbar = true;
          return;
        });
    },
    editBreach: function (payload) {
      const params = { breach_id: this.breachId };
      breachesBreachIdPut(payload, params, {})
        .then((response) => {
          this.snackbarContent = `${response.data.message}`;
          this.snackbar = true;

          this.dialog = false;
          store.dispatch("breachManagement/getSingleBreach");
        })
        .catch((error) => {
          console.warn(error.response);
          this.snackbarContent = `Breach error — ${error.response.data.message}`;
          this.snackbar = true;
          return;
        });
    },
    dateFormat: function (dateStr) {
      let newDate = formatDate(dateStr);
      if (newDate == "Invalid date") {
        return "---";
      } else {
        return newDate;
      }
    },
    dragFiles(e) {
      const fileItems = Array.from(e.dataTransfer.files);
      this.uploadFile(fileItems);
    },
    processFileUpload(param, file, blob) {
      breachesBreachIdRawfilePost({ filename: file.name }, param, {})
        .then((response) => {
          console.log(response);
          axios
            .request({
              method: "PUT",
              url: response.data.data.url,
              data: blob,
              headers: {
                "Content-Type": "",
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );

                file.loaded = Math.round(
                  progressEvent.loaded / 1000000
                ).toFixed(2);
                file.total = Math.round(progressEvent.total / 1000000).toFixed(
                  2
                );
                file.value = percentCompleted;
                this.forceRerender();
              },
            })
            .then(() => {
              file.value = 100;
              file.loading = false;
              store.dispatch("breachManagement/listFilenames");
              for (let i = 0; i < this.files.length; i++) {
                if (this.files[i].name == file.name.replace(/^.*[\\\/]/, "")) {
                  this.files.splice(i, 1);
                }
              }
            });
        })
        .catch((error) => {
          console.warn(error.response);
          this.snackbarContent = `Breach error — ${error.response.data.message} - ${file.name}`;
          this.snackbar = true;
          file.loading = false;
          for (let i = 0; i < this.files.length; i++) {
            if (this.files[i].name == file.name.replace(/^.*[\\\/]/, "")) {
              this.files.splice(i, 1);
            }
          }
          return;
        });
    },
    uploadFile: function (files) {
      this.files = this.files.concat(files);
      const param = { breach_id: this.breachId };
      for (const file of files) {
        file.loading = true;
        const blob = new Blob([file], { type: file.type });
        console.log(blob);
        if (blob.size > 5000000000) {
          this.snackbarContent = `This file is over 5GB and needs to be uploaded manually. Please use the AWS cli to upload the file to the proper S3 location:\n\n${this.breach.commands.aws_cp_local_to_raw}`;
          this.snackbar = true;
          file.loading = false;
        } else {
          this.processFileUpload(param, file, blob);
        }
      }
    },
  },
  computed: {
    topLeftTableInfoDialog() {
      return this.formattedStats.filter(
        (s) =>
          s.key == "partitions_checked" ||
          s.key == "missing_key" ||
          s.key == "total_records_processed" ||
          s.key == "total_records_normalized"
      );
    },
    topRightTableInfoDialog() {
      return this.formattedStats.filter(
        (s) =>
          s.key == "ends_in_comma" ||
          s.key == "ends_in_slashes" ||
          s.key == "begin_or_end_braces_brackets" ||
          s.key == "begin_or_end_quotes" ||
          s.key == "contains_json_key" ||
          s.key == "contains_html" ||
          s.key == "contains_byte_codes" ||
          s.key == "contains_special_chars"
      );
    },
    bottomTableColumnHeadersInfoDialog() {
      return this.formattedStats.filter(
        (s) =>
          s.key == "moved_from" ||
          s.key == "moved_to" ||
          s.key == "normalized_totals" ||
          s.key == "original_totals" ||
          s.key == "succeeded_validation" ||
          s.key == "skipped_validation" ||
          s.key == "totals"
      );
    },
    bottomTableRowHeadersInfoDialog() {
      const rowKeys = [];
      for (const rk of this.bottomTableColumnHeadersInfoDialog) {
        for (const r of Object.values(rk)) {
          for (const k of Object.keys(r)) {
            let m = k.replace(/_/g, " ");
            rowKeys.push(m);
          }
        }
      }
      const uniqueKeys = [...new Set(rowKeys)];
      const formatKeys = uniqueKeys.filter((x) => isNaN(x));
      return formatKeys;
    },
    formattedStats() {
      let formattedStats = [];
      if (this.stats) {
        for (const [key, value] of Object.entries(this.stats)) {
          let text = key.replace(/_/g, " ");
          formattedStats.push({ text: text, key: key, value: value });
        }
        return formattedStats;
      }
    },
    formattedStatus: function () {
      console.log(this.currentStatus);
      if (this.currentStatus) {
        if (this.currentStatus == "NEW") {
          return this.statusFilters.filter(
            (x) =>
              x == "PARTITIONING IN PROGRESS" ||
              x == "PARTITIONING COMPLETE" ||
              x == "INVALID" ||
              x == "ON HOLD"
          );
        }
        if (this.currentStatus == "INVALID") {
          return this.statusFilters.filter(
            (x) => x == "PARTITIONING IN PROGRESS"
          );
        }
        if (this.currentStatus == "ON HOLD") {
          return this.statusFilters.filter(
            (x) => x == "PARTITIONING IN PROGRESS" || x == "INVALID"
          );
        }
        if (this.currentStatus == "PARTITIONING IN PROGRESS") {
          return this.statusFilters.filter(
            (x) =>
              x == "PARTITIONING COMPLETE" || x == "INVALID" || x == "ON HOLD"
          );
        }
        if (this.currentStatus == "PARTITIONING NEEDS REVIEW") {
          return this.statusFilters.filter(
            (x) =>
              x == "PARTITIONING IN PROGRESS" ||
              x == "PARTITIONING COMPLETE" ||
              x == "INVALID"
          );
        }
        if (this.currentStatus == "NORMALIZATION NEEDS REVIEW") {
          return this.statusFilters.filter(
            (x) =>
              x == "PARTITIONING COMPLETE" ||
              x == "NORMALIZATION COMPLETE" ||
              x == "PARTITIONING IN PROGRESS" ||
              x == "INVALID"
          );
        }
        if (this.currentStatus == "LOADING NEEDS REVIEW") {
          return this.statusFilters.filter(
            (x) =>
              x == "PARTITIONING IN PROGRESS" ||
              x == "PARTITIONING COMPLETE" ||
              x == "NORMALIZATION COMPLETE" ||
              x == "LOADING COMPLETE"
          );
        }
        if (this.currentStatus == "NORMALIZATION COMPLETE") {
          return this.statusFilters.filter((x) => x == "LOADING IN PROGRESS");
        }
        if (this.currentStatus == "PARTITIONING COMPLETE") {
          return this.statusFilters.filter(
            (x) => x == "NORMALIZATION IN PROGRESS"
          );
        }
        if (this.currentStatus == "LOADING IN PROGRESS") {
          return this.statusFilters.filter(
            (x) => x == "LOADING COMPLETE" || x == "LOADING NEEDS REVIEW"
          );
        }
        if (this.currentStatus == "NORMALIZATION IN PROGRESS") {
          return this.statusFilters.filter(
            (x) =>
              x == "NORMALIZATION NEEDS REVIEW" ||
              x == "NORMALIZATION COMPLETE" ||
              x == "INVALID"
          );
        }
      }
    },
    currentStatus: function () {
      if (this.breach.status) {
        return this.breach.status[this.breach.status.length - 1].status;
      }
    },
    isNewBreach: function () {
      if (this.breach.status) {
        return (
          this.breach.status[this.breach.status.length - 1].status == "NEW"
        );
      }
    },
    filenames: function () {
      return store.state.breachManagement.filenames;
    },
    breach: function () {
      return store.state.breachManagement.breach;
    },
    breachId: function () {
      return store.state.breachManagement.breachId;
    },
    primaryInfo: function () {
      const info = [];
      for (const key of this.primaryInfoKeys) {
        info.push([key[0], this.metaInfo[key[0]]]);
      }
      return info;
    },
    secondaryInfo: function () {
      const info = [];
      for (const key of this.secondaryInfoKeys) {
        info.push([key[0], this.metaInfo[key[0]]]);
      }
      return info;
    },
    metaInfo: function () {
      const metaInfoKeys = this.primaryInfoKeys
        .concat(this.secondaryInfoKeys)
        .concat(this.descriptionKeys)
        .concat(this.notesKeys);

      const metaInfo = {};

      for (const key of metaInfoKeys) {
        let value = this.breach[key[1]];
        if (!value || value == "") {
          value = "---";
        }

        const dateKeys = ["Acquired", "Released"];

        if (dateKeys.includes(key[0])) {
          value = this.dateFormat(this.breach[key[1]]);
        }

        metaInfo[key[0]] = value;
      }

      return metaInfo;
    },
  },
  watch: {
    breach: {
      handler: function () {
        for (const field of this.breachFields) {
          field.value = this.breach[field.key];
        }
      },
      deep: true,
      immediate: true,
    },
    invalids: {
      handler: function (val, oldVal) {
        if (val !== "") {
          console.debug(val, oldVal);
          axios({
            url: val,
            method: "GET",
            headers: {
              "Content-Type": "",
            },
            responseType: "blob",
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "invalids.csv");
            document.body.appendChild(link);
            link.click();
          });
          this.invalids = "";
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
