<template>
  <v-dialog
    :overlay-color="'#000000'"
    :overlay-opacity="0.9"
    v-model="show"
    max-width="500"
  >
    <v-card class="rounded-0">
      <v-icon class="float-right mt-4 mr-6" @click="show = false"
        >mdi-close</v-icon
      >
      <v-card-title class="mb-5">
        <h4 class="white--text">{{ title }}</h4>
      </v-card-title>
      <v-card-text class="body-2 white--text font-weight-light">
        <v-form @submit="formHandler">
          <v-row>
            <v-col cols="8">
              <span v-for="(field, index) in fields" :key="index">
                <v-text-field
                  style="display: block; margin-bottom: 15px"
                  v-if="field.widget == 'text'"
                  :label="field.text"
                  :name="field.key"
                  :value="field.value"
                  @change="updateField(field.key, $event)"
                  dense
                />
                <v-text-field
                  style="display: block; margin-bottom: 15px"
                  v-if="field.widget == 'number'"
                  :label="field.text"
                  :name="field.key"
                  :value="field.value"
                  @change="updateField(field.key, $event)"
                  dense
                />
                <v-text-field
                  style="display: block; margin-bottom: 15px"
                  v-if="field.widget == 'readOnly'"
                  :label="field.text"
                  :name="field.key"
                  :value="field.value"
                  :readonly="true"
                  :disabled="true"
                  dense
                />
                <span v-if="field.widget == 'date'" class="fieldLabel">{{
                  field.text
                }}</span>
                <date-picker
                  style="display: block; margin-bottom: 30px"
                  v-if="field.widget == 'date'"
                  :initialStartDate="field.value"
                  :range="false"
                  :submitHandler="saveDate(field.key)"
                ></date-picker>
                <v-checkbox
                  style="display: inline; margin-bottom: 0px"
                  v-if="field.widget == 'checkbox'"
                  :label="field.text"
                  v-model="field.value"
                  @change="updateField(field.key, $event)"
                ></v-checkbox>
                <v-textarea
                  style="display: block; margin-bottom: 15px"
                  v-if="field.widget == 'textarea'"
                  :label="field.text"
                  :name="field.key"
                  :value="field.value"
                  @change="updateField(field.key, $event)"
                  filled
                  flat
                />
              </span>
            </v-col>
          </v-row>
          <v-btn
            color="#E86051"
            depressed
            width="120"
            height="34"
            type="submit"
          >
            Submit
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";

function bullshitToFixVuetifyStyling() {
  /*   window.setTimeout(() => {
    const textarea = document.querySelector("textarea");
    const inputId = textarea.getAttribute("id");
    const label = document.querySelector(`label[for="${inputId}"]`);
    console.log(textarea.textContent);
    if (textarea.textContent == "") {
      label.style = "left: 10px; right: auto; position: absolute;";
    } else {
      label.style = "left: 0px; right: auto; position: absolute;";
    }
  }, 200); */
}

export default {
  name: "BreachManagementDialog",
  components: {
    DatePicker,
  },
  props: {
    title: {
      type: String,
      default: "Breach Management Dialog",
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    submitHandler: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    payload: {},
  }),
  methods: {
    formHandler: function (e) {
      e.preventDefault();
      console.log(this.payload);
      this.submitHandler(this.payload);
    },
    saveDate: function (key) {
      const updateFn = this.updateField;
      return function (dates) {
        updateFn(key, dates[0]);
      };
    },
    updateField: function (k, v) {
      this.payload[k] = v;
    },
  },
  computed: {
    show: {
      get: function () {
        return this.isOpen;
      },
      set: function () {
        this.$emit("close");
      },
    },
  },
  watch: {
    show(isVisible) {
      if (isVisible) {
        bullshitToFixVuetifyStyling();
      }
    },
  },
};
</script>

<style>
.fieldLabel {
  font-size: 12px;
  color: #ced3da;
  margin-bottom: 1px;
}
</style>
