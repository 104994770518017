<template>
  <div>
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-chip outlined v-on="on" :style="{ width: chipWidth }">
          <v-menu><div>the menu</div> </v-menu>
          <v-icon small color="#e86051" class="mr-1">mdi-calendar-today</v-icon>
          <text-field-no-underline
            :inputWidth="inputWidth"
            autocomplete="off"
            :placeholder="placeholder"
            v-model="dateRangeText"
            readonly
          ></text-field-no-underline>
        </v-chip>
      </template>
      <v-date-picker no-title :range="range" v-model="dateData"></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";

import TextFieldNoUnderline from "@/components/TextFieldNoUnderline.vue";

export default {
  name: "date-picker",
  components: {
    TextFieldNoUnderline,
  },
  props: {
    clearInput: {
      type: Boolean,
      default: false,
    },
    submitHandler: {
      type: Function,
      default: (dates) => console.log(dates),
    },
    range: {
      type: Boolean,
      default: true,
    },
    initialStartDate: {
      type: String,
      default: null,
    },
    initialEndDate: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    dates: [
      moment().subtract(365, "days").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
    ],
    formattedDates: [],
  }),
  mounted() {
    if (this.isValidDate(this.initialStartDate)) {
      this.dates = [
        moment(this.initialStartDate).format("YYYY-MM-DD"),
        moment(this.initialEndDate).format("YYYY-MM-DD"),
      ];
    } else {
      this.dates = [null, null];
    }
  },
  methods: {
    isValidDate(d) {
      let parsedDate = moment(d).format("YYYY-MM-DD");
      if (parsedDate == "Invalid date") {
        return false;
      }
      return true;
    },
  },
  computed: {
    placeholder: function () {
      if (!this.range) {
        return "Enter Date";
      }
      return "Start Date - End Date";
    },
    chipWidth: function () {
      if (!this.range) {
        return "140px";
      }
      return "240px";
    },
    inputWidth: function () {
      if (!this.range) {
        return "90px";
      }
      return "190px";
    },
    dateData: {
      get: function () {
        if (!this.range) {
          return this.dates[0];
        } else {
          return this.dates;
        }
      },
      set: function (newValue) {
        if (!this.range) {
          this.dates = [newValue];
        } else {
          this.dates = newValue;
        }
      },
    },
    dateRangeText: {
      get: function () {
        if (this.clearInput) {
          return "---";
        }
        if (!this.range) {
          let dateStr = moment(this.dates[0], "YYYY-MM-DD").format(
            "DD-MMM-YYYY"
          );
          if (dateStr == "Invalid date") {
            dateStr = "---";
          }

          return dateStr;
        }
        const startDate = moment(this.dates[0], "YYYY-MM-DD");
        const endDate = moment(this.dates[1], "YYYY-MM-DD");
        if (!startDate.isValid()) {
          return;
        }
        if (!endDate.isValid()) {
          return;
        }
        if (startDate > endDate) {
          alert("Start date cannot be after end date");
          return;
        }
        const sd = moment(this.dates[0], "YYYY-MM-DD").format("DD-MMM-YYYY");
        const ed = moment(this.dates[1], "YYYY-MM-DD").format("DD-MMM-YYYY");

        return `${sd} - ${ed}`;
      },
      set: function (newValue) {
        if (!this.range) {
          this.formattedDates = [];
          const textDates = newValue.split(" - ");
          let startDate;
          startDate = moment(textDates[0], "DD-MMM-YYYY");
          this.formattedDates.push(startDate);
          return;
        }
        this.formattedDates = [];
        const textDates = newValue.split(" - ");
        let startDate;
        let endDate;
        if (textDates[1].length == 9) {
          startDate = moment(textDates[0], "DD-MMM-YYYY");
          this.formattedDates.push(startDate);
        }
        if (textDates[1].length == 9) {
          endDate = moment(textDates[1], "DD-MMM-YYYY");
          this.formattedDates.push(endDate);
        }
      },
    },
  },
  watch: {
    dates: function (val) {
      this.submitHandler(val);
    },
    clearInput: function (val) {
      if (val && this.range) {
        this.dates = [
          moment().subtract(365, "days").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ];
      }
    },
    formattedDates: function (val) {
      if (val.length == 2) {
        if (val[0]._i.length == 11 && val[1]._i.length == 11) {
          let sd = val[0].format("YYYY-MM-DD");
          let ed = val[1].format("YYYY-MM-DD");
          this.submitHandler([sd, ed]);
        }
      }
    },
  },
};
</script>
